import React from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/react';
import { theme } from '../../../domain/theme';
import { SmartLink } from '../../SmartLink';
import { Image } from '../../Image';
import {
  getMainImages,
  getPrimaryTaxonomyData
} from '../../../domain/utils/contentUtils';
import { stripTags } from '../../../domain/utils/stringUtils';

export const CarouselCard = ({ data, fontColor, aspect, isAboveTheFold }) => {
  const imageWidth = 340;
  const styles = {
    cardStackCard: css`
      white-space: initial;
      vertical-align: top;
      position: relative;
      display: inline-block;
      margin: 0 10px;
      &:first-of-type {
        margin: 0 10px 0 0;
      }
      &:last-child {
        margin: 0 0 0 10px;
      }
      width: calc(66.6666666% - 20px);
      ${theme.mq.tablet} {
        width: calc(40% - 20px);
      }
      ${theme.mq.small_desktop} {
        width: calc(25% - 20px);
      }
      img {
        transition: transform 0.4s ease-in-out;
      }
      &:hover img {
        transform: scale(1.1);
      }
    `,
    imageContainer: css`
      position: relative;
      overflow: hidden;
    `,
    textContainer: css`
      width: 95%;
      display: flex;
      flex-direction: column;
      gap: 16px;
      padding-top: 22px;
      text-align: left;
      a {
        color: inherit;
        &:hover {
          text-decoration: underline;
        }
      }
    `,
    sponsoredContent: css`
      display: inline-block;
      background-color: ${fontColor};
      color: ${fontColor === 'white' ? 'black' : 'white'};
      ${theme.font.family('boldWeb')};
      font-size: 12px;
      font-weight: 600;
      letter-spacing: 3px;
      line-height: 1;
      padding: 8px 8px 6px;
      text-transform: uppercase;
      white-space: nowrap;
    `,
    taxonomy: css`
      color: grey;
      ${theme.font.family('boldWeb')};
      font-size: 12px;
      font-weight: 600;
      letter-spacing: 3px;
      line-height: 1;
      padding: 8px 0 6px 0;
      text-transform: uppercase;
    `,
    cardTitle: css`
      color: ${fontColor};
      ${theme.font.family('medium')};
      font-size: 22px;
      font-weight: 500;
      line-height: 1.27;
      overflow: hidden;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 4;
      display: -webkit-box;
    `,
    cardDescription: css`
      color: ${fontColor};
      ${theme.font.family('regular')};
      font-size: 16px;
      font-weight: 400;
      margin-bottom: 18px;
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 4;
      -webkit-box-orient: vertical;
    `
  };

  const title =
    data.title ||
    data.carouselRefCard?.entity?.promoTitle ||
    data.carouselRefCard?.entity?.title;
  const description = data.hideDescription
    ? null
    : data.subtitle ||
      stripTags(data.carouselRefCard?.entity?.promoSummary?.value) ||
      data.carouselRefCard?.entity?.subHeadline;
  const image =
    data.cardThumbnail?.entity ||
    data.cardThumbnail ||
    (data.carouselRefCard?.entity
      ? getMainImages(data.carouselRefCard.entity)[0]
      : null);
  if (image) {
    image.focalPoint = data.focalPoint || image.focalPoint;
  }
  if (image.entity) {
    image.entity.focalPoint = data.focalPoint || image.entity?.focalPoint;
  }

  const link =
    data.carouselRefCard?.entity?.link?.url?.path ||
    data.carouselRefCard?.entity?.url?.path;
  const isSponsoredContent =
    data.carouselRefCard?.entity?.sponsoredTextCard &&
    data.carouselRefCard?.entity?.branded;
  const taxonomyData =
    !data.hidePtt && !isSponsoredContent && data.carouselRefCard
      ? getPrimaryTaxonomyData(data.carouselRefCard.entity)
      : null;

  return (
    <div css={styles.cardStackCard}>
      <div css={styles.imageContainer}>
        {!!image && !!link && (
          <SmartLink to={link} title={title}>
            <Image
              entity={image.entity || image}
              maxWidths={{
                mobile: imageWidth,
                tablet: imageWidth,
                small_desktop: imageWidth,
                large_desktop: imageWidth
              }}
              aspect={aspect}
              loading={isAboveTheFold ? 'eager' : 'lazy'}
            />
          </SmartLink>
        )}
        {!!image && !link && (
          <Image
            entity={image.entity || image}
            maxWidths={{
              mobile: imageWidth,
              tablet: imageWidth,
              small_desktop: imageWidth,
              large_desktop: imageWidth
            }}
            aspect={aspect}
            loading={isAboveTheFold ? 'eager' : 'lazy'}
          />
        )}
      </div>
      <div css={styles.textContainer}>
        {isSponsoredContent && (
          <div>
            <span css={[styles.sponsoredContent]}>
              {data.carouselRefCard.entity.sponsoredTextCard}
            </span>
          </div>
        )}
        {!!taxonomyData?.tagUrl && (
          <div css={styles.taxonomy}>
            <SmartLink to={taxonomyData.tagUrl}>{taxonomyData.tag}</SmartLink>
          </div>
        )}
        {!!title && (
          <div css={styles.cardTitle}>
            {link ? (
              <SmartLink to={link} title={title}>
                {title}
              </SmartLink>
            ) : (
              title
            )}
          </div>
        )}
        {!!description && <div css={styles.cardDescription}>{description}</div>}
      </div>
    </div>
  );
};

CarouselCard.defaultProps = {
  aspect: '1x1'
};

CarouselCard.propTypes = {
  data: PropTypes.objectOf(PropTypes.any).isRequired,
  fontColor: PropTypes.string.isRequired,
  aspect: PropTypes.string,
  isAboveTheFold: PropTypes.bool.isRequired
};
