import React from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/react';
import { theme } from '../../../domain/theme';
import { CarouselCard } from './CarouselCard';
import { getColors } from '../../../domain/utils/colorUtils';
import { ParagraphWrapper } from '../ParagraphWrapper';
import { Branded } from '../../Branded';
import { facePaint } from '../../../domain/theme/utils/facePaint';
import { HorizontalScrollContainer } from '../../responsive/organisms/HorizontalScrollContainer';

const getStyles = (backgroundColor, fontColor, hasHeader) => {
  return {
    mainContainer: css`
      background-color: ${backgroundColor};
      color: ${fontColor};
      width: 100%;
      ${facePaint({
        'padding-top': hasHeader ? null : ['20px', '20px', '60px', '60px']
      })};
    `,
    header: css`
      padding: 56px 20px 0 20px;
      display: flex;
      flex-direction: column;
      gap: 20px;
      position: relative;
      ${theme.mq.tablet} {
        padding: 80px 50px 16px 50px;
      }
    `,
    mainTitle: css`
      ${theme.font.family('boldWeb')};
      font-size: 32px;
      font-weight: 600;
      letter-spacing: 3.6px;
      line-height: 1.42;
      margin: auto;
      max-width: 1080px;
      text-align: center;
      text-transform: uppercase;
      ${theme.mq.small_desktop} {
        line-height: 1.3;
        font-size: 44px;
      }
    `,
    mainSubtitle: css`
      ${theme.font.family('regular')};
      font-size: 18px;
      font-weight: 400;
      line-height: 1.5;
      margin: auto;
      max-width: 1080px;
      text-align: center;
      letter-spacing: normal;
    `,
    yellowLine: css`
      background-color: #fc0;
      height: 3px;
      margin: 0 auto 20px auto;
      width: 40px;
    `,
    carouselWrapper: css`
      display: block;
      position: relative;
      text-align: center;
      margin: 0 20px;
    `,
    scrollContainer: css`
      margin: 0 auto;
      max-width: 1600px;
      padding: 24px 0 88px;
      overflow-x: scroll;
      scroll-behavior: smooth;
      position: relative;
      -ms-overflow-style: none; /* Internet Explorer 10+ */
      scrollbar-width: none; /* Firefox */
      ${theme.mq.tablet} {
        padding: 32px 0 112px 0;
      }
      &::-webkit-scrollbar {
        display: none; /* Safari and Chrome */
      }
    `,
    links: css`
      white-space: nowrap;
      width: 100%;
      opacity: 1;
      transition: opacity 0.5s ease-in-out;
      a {
        white-space: initial;
        display: block;
      }
    `
  };
};

const CarouselInner = ({
  data,
  aspect,
  transition,
  cardClass,
  cardRel,
  isAboveTheFold
}) => {
  const {
    carouselTitle,
    carouselSubtitle,
    carouselCard,
    brandedLogo,
    brandedClickThrough
  } = data;
  const hasHeader = !!(brandedLogo || carouselTitle || carouselSubtitle);
  const branded = { logo: brandedLogo, link: brandedClickThrough?.url?.path };
  const colors = getColors(data.backgroundColor);
  const backgroundColor = colors.background;
  const fontColor = colors.color;
  const styles = getStyles(backgroundColor, fontColor, hasHeader);

  return carouselCard.length > 3 ? (
    <section css={styles.mainContainer}>
      <div css={styles.header}>
        {!!branded?.logo && (
          <Branded
            logo={branded.logo}
            link={branded.link}
            fontColor={fontColor}
          />
        )}
        {carouselTitle && <h2 css={styles.mainTitle}>{carouselTitle}</h2>}
        {carouselSubtitle && (
          <p css={styles.mainSubtitle}>{carouselSubtitle}</p>
        )}
        {(carouselTitle || carouselSubtitle) && <div css={styles.yellowLine} />}
      </div>

      <div css={styles.carouselWrapper}>
        <div css={styles.scrollContainer}>
          <HorizontalScrollContainer
            backgroundColor={backgroundColor}
            gradient={{ prev: ['15%', '100%'], next: ['0%', '85%'] }}
            align="center"
            width={['100%', '90%']}
            margin="0 auto"
            arrow={{
              color: fontColor,
              size: 32,
              prev: {
                container: [0, 0, '-60px'],
                margin: ['0 0 0 -8px', '0 0 0 -4.5vw', '0 auto'],
                float: 'none'
              },
              next: {
                container: [0, 0, '-60px'],
                margin: ['0 -8px 0 0', '0 -4.5vw 0 0', '0 auto'],
                float: ['right', 'right', 'none', 'none']
              }
            }}
          >
            <div css={styles.links}>
              {carouselCard.map(card => {
                return (
                  <CarouselCard
                    key={card.entity.key || card.entity.id}
                    data={card.entity}
                    aspect={aspect}
                    transition={transition}
                    fontColor={fontColor}
                    cardClass={cardClass}
                    cardRel={cardRel}
                    isAboveTheFold={isAboveTheFold}
                  />
                );
              })}
            </div>
          </HorizontalScrollContainer>
        </div>
      </div>
    </section>
  ) : (
    <div />
  );
};

CarouselInner.defaultProps = {
  aspect: undefined,
  transition: undefined,
  cardClass: undefined,
  cardRel: undefined
};

CarouselInner.propTypes = {
  data: PropTypes.objectOf(PropTypes.any).isRequired,
  aspect: PropTypes.string,
  transition: PropTypes.bool,
  cardClass: PropTypes.string,
  cardRel: PropTypes.string,
  isAboveTheFold: PropTypes.bool.isRequired
};

export const Carousel = ParagraphWrapper(CarouselInner);
